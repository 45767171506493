import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  NumberInputProps
} from '@chakra-ui/react'
import { useField } from 'formik'
import omit from 'lodash/omit'
import * as React from 'react'

type ConnectedNumberInputProps = NumberInputProps & {
  label?: string
  name: string
  unit?: string
  disabled?: boolean
  placeholder?: string
  allowDecimals?: boolean
  showError?: boolean
}

const ConnectedNumberInput: React.FC<ConnectedNumberInputProps> = ({
  label,
  unit,
  placeholder,
  disabled,
  showError,
  allowDecimals,
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest.name)

  const isInvalid = !!(meta.touched && meta.error)

  return (
    <Flex flexDirection="column" width={rest.width} mr={rest.mr} ml={rest.ml} mt={rest.mt}>
      <FormControl isInvalid={isInvalid}>
        {label && (
          <FormLabel
            htmlFor={field.name}
            fontSize={{ base: 14, md: 'unset' }}
            fontWeight="600"
            color={isInvalid ? 'error.500' : 'text'}
          >
            {label}
          </FormLabel>
        )}
        <InputGroup width="100%">
          {!!unit && <InputLeftAddon>{unit}</InputLeftAddon>}
          <NumberInput
            {...omit(rest, ['bg'])}
            precision={0}
            step={1}
            isDisabled={disabled}
            width="100%"
            value={field.value}
            onChange={(val) => {
              if (allowDecimals) {
                // Allow only digits and a single decimal point
                const sanitizedValue = val.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
                helpers.setValue(sanitizedValue)
              } else {
                // Allow only whole numbers (no decimals)
                const sanitizedValue = val.replace(/\D/g, '') // Removes non-numeric characters
                helpers.setValue(sanitizedValue)
              }
            }}
          >
            <NumberInputField
              {...field}
              id={field.name}
              focusBorderColor="accent.500"
              placeholder={placeholder || ''}
              variant="filled"
              bg={isInvalid ? 'error.100' : rest.bg}
              color={isInvalid ? 'error.500' : 'text'}
              borderRadius="50px"
              fontColor="black"
            />
          </NumberInput>
        </InputGroup>
        {showError && isInvalid && (
          <FormErrorMessage color="red.500">{meta.error}</FormErrorMessage>
        )}
      </FormControl>
    </Flex>
  )
}

export default ConnectedNumberInput

ConnectedNumberInput.defaultProps = {
  mb: 2,
  fontWeight: 'lighter',
  disabled: false,
  width: '100%',
  allowDecimals: false,
  bg: 'background'
}
